<template>
  <page-content>

    <common-table
      ref="table"
      path="web/camera"
      :columns="columns">

      <template slot="search">
<!--        <a-button  ghost type="primary" @click="add">新增</a-button>-->
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="30" :src="record.avatarUrl">{{record.nickName}}</a-avatar>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-view  @click="view(record)"></action-view>
      </template>

    </common-table>


  </page-content>
</template>
<script>
export default {

  data () {
    return {

    }
  },

  computed: {
    columns () {
      return [
        {
          title: '用户头像',
          dataIndex: 'avatarUrl',
          scopedSlots: {customRender: 'logo'},
          width: 80
        },
        {
          title: '用户',
          dataIndex: 'nickName',
        },
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '直播地址',
          dataIndex: 'link',
          ellipsis: true,
        },
        {
          title: '添加时间',
          dataIndex: 'createdAt'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width:60,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  methods: {
    view (record) {
      this.$router.push('/device/camera/'+record.id)
    },

    getList () {
      this.$refs.table.getData()
    }

  }
}
</script>
<style lang="less" scoped>

</style>
